import * as actionTypes from "../actions/actionTypes";

const initialState = {
  error: null,
  loading: false,
  dbLoaded: false,
};

const loadDBsStart = (state, action) => {
  return { ...state, error: null, loading: true };
};

const loadDBsSuccess = (state, action) => {
  return {
    ...state,
    error: null,
    loading: false,
    dbLoaded: action.success,
  };
};

const loadDBsFail = (state, action) => {
  return {
    ...state,
    error: action.error,
    loading: false,
    dbLoaded: false,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_DBS_START:
      return loadDBsStart(state, action);
    case actionTypes.LOAD_DBS_SUCCESS:
      return loadDBsSuccess(state, action);
    case actionTypes.LOAD_DBS_FAIL:
      return loadDBsFail(state, action);
    default:
      return state;
  }
};

export default reducer;
