import React, { Suspense, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Route, Switch, Link } from "react-router-dom";
import classes from "./App.module.scss";
import IndexPage from "./Pages";
// import Publishers from "./containers/Publishers/Publishers";
// import Users from "./containers/Users/Users";
// import WYSIWYG from "./components/WYSIWYG/wysiwyg";
// import NewToolForm from "./components/NewToolForm/NewtoolForm";
import * as actions from "./store/actions";
import Spinner from "./components/UI/Spinner/Spinner";

const Publishers = React.lazy(() =>
  import("./containers/Publishers/Publishers")
);
const Users = React.lazy(() => import("./containers/Users/Users"));

function App() {
  // console.log("APP");
  const dispatch = useDispatch();

  const checkLoggedIn = useCallback(() => dispatch(actions.authCheckState()), [
    dispatch,
  ]);

  const loadDBs = useCallback(() => dispatch(actions.loadDBs()), [dispatch]);

  checkLoggedIn();
  loadDBs();

  return (
    <div className={classes.App}>
      <Switch>
        <Route
          path="/publishers"
          // component={Publishers}
          render={(props) => (
            <Suspense
              fallback={
                <div>
                  <Spinner type="bounce" />
                </div>
              }
            >
              <Publishers {...props} />
            </Suspense>
          )}
        />
        <Route
          path="/users"
          // component={Users}
          render={(props) => (
            <Suspense
              fallback={
                <div>
                  <Spinner type="bounce" />
                </div>
              }
            >
              <Users {...props} />
            </Suspense>
          )}
        />
        {/*<Route path="/new" exact component={NewToolForm} />*/}
        {/*<Redirect from="/" to="/404" />*/}
        <Route path="/" component={IndexPage} />
        <Route
          render={(props) => (
            <h1 style={{ padding: "10rem" }}>
              404 Not Found! <Link to={props.match.url}>Go Home </Link>
            </h1>
          )}
        />
      </Switch>
    </div>
  );
}

export default App;
