import React, { useCallback, useEffect } from "react";
import Header from "../../components/Header/Header";
import MainArea from "../../components/MainArea/MainArea";
import * as actions from "../../store/actions";
import { useDispatch } from "react-redux";

const Home = (props) => {
  const dispatch = useDispatch();

  const setPage = useCallback((payload) => dispatch(actions.setPage(payload)), [
    dispatch,
  ]);

  useEffect(() => {
    setPage("home");

    return () => {
      setPage("");
    };

    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Header />
      <MainArea />
      {/*SampleResults*/}
    </div>
  );
};

export default Home;
