import * as actionTypes from "../actions/actionTypes";

const initialState = {
  token: null,
  userId: null,
  error: null,
  name: "",
  role: "",
  photo: "",
  loading: false,
  verified: false,
  registrationStatus: null,
  authRedirectPath: "/",
};

const authStart = (state, action) => {
  return { ...state, error: null, loading: true };
};

const clearError = (state, action) => {
  return { ...state, error: null, loading: false };
};

const authSuccess = (state, action) => {
  return {
    ...state,
    token: action.idToken,
    userId: action.userId,
    verified: action.verified,
    name: action.name,
    role: action.role,
    photo: action.photo,
    error: null,
    loading: false,
  };
};

const authFail = (state, action) => {
  return {
    ...state,
    error: action.error,
    loading: false,
  };
};

const authLogout = (state, action) => {
  return {
    ...state,
    token: null,
    userId: null,
    error: null,
    name: "",
    role: "",
    photo: "",
    loading: false,
    verified: false,
    registrationStatus: null,
  };
};

const setAuthRedirectPath = (state, action) => {
  return { ...state, authRedirectPath: action.path };
};

//Registration
const registrationStart = (state, action) => {
  return { ...state, error: null, loading: true };
};

const registrationSuccess = (state, action) => {
  return {
    ...state,
    registrationStatus: action.message,
    error: null,
    loading: false,
  };
};

const registrationFail = (state, action) => {
  return {
    ...state,
    error: action.error,
    loading: false,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state, action);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state, action);
    case actionTypes.AUTH_CLEAR_ERROR:
      return clearError(state, action);
    case actionTypes.SET_AUTH_REDIRECT_PATH:
      return setAuthRedirectPath(state, action);
    case actionTypes.REGISTRATION_START:
      return registrationStart(state, action);
    case actionTypes.REGISTRATION_SUCCESS:
      return registrationSuccess(state, action);
    case actionTypes.REGISTRATION_FAIL:
      return registrationFail(state, action);
    default:
      return state;
  }
};

export default reducer;
