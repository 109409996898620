//Registration
import * as actionTypes from "./actionTypes";

export const fetchToolsStart = () => {
  return {
    type: actionTypes.FETCH_TOOLS_START,
  };
};

export const fetchToolsSuccess = (tools, count) => {
  return {
    type: actionTypes.FETCH_TOOLS_SUCCESS,
    tools,
    count,
  };
};

export const fetchToolsFail = (error) => {
  return {
    type: actionTypes.FETCH_TOOLS_FAIL,
    error,
  };
};

export const fetchTools = (query) => {
  return {
    type: actionTypes.FETCH_TOOLS,
    query,
  };
};

export const fetchSavedToolsStart = () => {
  return {
    type: actionTypes.FETCH_SAVED_TOOLS_START,
  };
};

export const fetchSavedToolsFail = (error) => {
  return {
    type: actionTypes.FETCH_SAVED_TOOLS_FAIL,
    error,
  };
};

export const fetchSavedTools = (index) => {
  return {
    type: actionTypes.FETCH_SAVED_TOOLS,
    index,
  };
};

export const fetchSavedToolsSuccess = (tools, payload) => {
  return {
    type: actionTypes.FETCH_SAVED_TOOLS_SUCCESS,
    tools,
    payload,
  };
};

export const saveToolStart = () => {
  return {
    type: actionTypes.SAVE_TOOL_START,
  };
};

export const saveToolSuccess = (payload, saveInState = false) => {
  return {
    type: actionTypes.SAVE_TOOL_SUCCESS,
    payload,
    saveInState,
  };
};

export const saveToolFail = (error) => {
  return {
    type: actionTypes.SAVE_TOOL_FAIL,
    error,
  };
};

export const saveTool = (payload, saveInState = false) => {
  return {
    type: actionTypes.SAVE_TOOL,
    payload,
    saveInState,
  };
};

export const deleteToolStart = () => {
  return {
    type: actionTypes.DELETE_TOOL_START,
  };
};

export const deleteToolSuccess = (payload) => {
  return {
    type: actionTypes.DELETE_TOOL_SUCCESS,
    payload,
  };
};

export const deleteToolFail = (error) => {
  return {
    type: actionTypes.DELETE_TOOL_FAIL,
    error,
  };
};

export const deleteTool = (id, destination) => {
  return {
    type: actionTypes.DELETE_TOOL,
    id,
    destination,
  };
};

export const updateSections = (sections) => {
  return {
    type: actionTypes.UPDATE_TOOL_SECTIONS,
    sections,
  };
};

export const fetchToolByIDStart = () => {
  return {
    type: actionTypes.FETCH_TOOL_BY_ID_START,
  };
};

export const fetchToolByIDSuccess = (payload) => {
  return {
    type: actionTypes.FETCH_TOOL_BY_ID_SUCCESS,
    payload,
  };
};

export const fetchToolByIDFail = (error) => {
  return {
    type: actionTypes.FETCH_TOOL_BY_ID_FAIL,
    error,
  };
};

export const fetchToolByID = (id, destination) => {
  return {
    type: actionTypes.FETCH_TOOL_BY_ID,
    id,
    destination,
  };
};

export const postToolStart = () => {
  return {
    type: actionTypes.POST_TOOL_START,
  };
};

export const postToolSuccess = (payload) => {
  return {
    type: actionTypes.POST_TOOL_SUCCESS,
    payload,
  };
};

export const postToolFail = (error) => {
  return {
    type: actionTypes.POST_TOOL_FAIL,
    error,
  };
};

export const postTool = (payload) => {
  return {
    type: actionTypes.POST_TOOL,
    payload,
  };
};

export const clearToolData = () => {
  return {
    type: actionTypes.CLEAR_TOOL_DATA,
  };
};
