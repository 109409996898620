import { combineReducers } from "redux";
import { createSelector } from "reselect";
import authReducer from "./auths";
import uiReducer from "./ui";
import dbsReducer from "./dbs";

import toolsReducer, * as fromTools from "./tools";
const html2json = require("html2json").html2json;
const cloneDeep = require("lodash.clonedeep");

const rootReducer = combineReducers({
  ui: uiReducer,
  auth: authReducer,
  tools: toolsReducer,
  dbs: dbsReducer,
});

//Selectors
export const selectAllTools = (state) => state.tools.allTools;
export const selectSavedTools = (state) => state.tools.savedIDBTools;
export const selectUser = (state) => state.auth.userId;
export const selectMQuery = (_, props) => props.mQuery;

export const selectPostData = (state) => {
  return {
    toolID: state.tools.currentTool.toolID,
    name: state.tools.currentTool.name,
    publishedDate: state.tools.currentTool.published,
    authors: state.tools.currentTool.authors.map((author) => ({
      name: author,
    })),
    reference: state.tools.currentTool.reference,
    toolClass: state.tools.currentTool.toolClass,
    toolUsage: state.tools.currentTool.toolUsage,
    section: state.tools.currentTool.section.map((sect) => {
      const newSection = cloneDeep(sect);
      newSection.content = JSON.stringify(html2json(newSection.content));
      return newSection;
    }),
    collaborators: state.tools.currentTool.collaborators,
  };
};

export const selectPublishedTools = createSelector(
  selectAllTools,
  selectUser,
  (allTools, userId) => fromTools.selectPublishedTools(allTools, userId)
);

export const selectToolsData = () =>
  createSelector(selectAllTools, selectMQuery, (allTools, mQuery) =>
    fromTools.selectToolsData(allTools, mQuery)
  );

export const selectOtherToolsData = createSelector(selectAllTools, (allTools) =>
  fromTools.selectOtherToolsData(allTools)
);

export const selectSavedToolsData = createSelector(
  selectSavedTools,
  (savedTools) => fromTools.selectSavedToolsData(savedTools)
);

export const selectSavedOtherToolsData = createSelector(
  selectSavedTools,
  (savedTools) => fromTools.selectOtherToolsData(savedTools)
);

export default rootReducer;
