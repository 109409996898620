import * as actionTypes from "./actionTypes";

export const setMinified = (value) => {
  return {
    type: actionTypes.MINIFIED_LAYOUT,
    value,
  };
};

export const setNewForm = (value) => {
  return {
    type: actionTypes.ACTIVATE_NEW_TOOL_FORM,
    value,
  };
};

export const setEditMode = (value) => {
  return {
    type: actionTypes.EDIT_MODE_ON,
    value,
  };
};

export const tooltipON = (payload) => {
  return {
    type: actionTypes.TOOLTIP_ON,
    payload,
  };
};

export const tooltipOFF = () => {
  return {
    type: actionTypes.TOOLTIP_OFF,
  };
};

export const toastON = (payload) => {
  return {
    type: actionTypes.TOAST_ON,
    payload,
  };
};

export const toastOFF = () => {
  return {
    type: actionTypes.TOAST_OFF,
  };
};

export const setPage = (payload) => {
  return {
    type: actionTypes.SET_CURRENT_PAGE,
    payload,
  };
};
