import * as actionTypes from "./actionTypes";

export const loadDBsStart = () => {
  return {
    type: actionTypes.LOAD_DBS_START,
  };
};

export const loadDBsSuccess = (success) => {
  return {
    type: actionTypes.LOAD_DBS_SUCCESS,
    success,
  };
};

export const loadDBsFail = (error) => {
  return {
    type: actionTypes.LOAD_DBS_FAIL,
    error,
  };
};

export const loadDBs = () => {
  return {
    type: actionTypes.LOAD_DBS,
  };
};
