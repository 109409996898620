import * as actionTypes from "../actions/actionTypes";
const cloneDeep = require("lodash.clonedeep");
const json2html = require("html2json").json2html;

const initialState = {
  allTools: [],
  numTools: 0,
  savedIDBTools: [],
  noSavedTools: false,
  allViews: 0,
  loading: false,
  error: null,
  fetchSavedIDBToolLoading: false,
  fetchSavedIDBToolError: null,
  deleteToolLoading: false,
  deleteToolError: null,
  deleteToolMessage: null,
  successToolMessage: null,
  currentTool: {
    toolID: "",
    name: "",
    authors: [],
    published: new Date(),
    reference: "",
    toolClass: [],
    toolUsage: [],
    collaborators: [],
    section: [],
    createdAt: null,
  },
};

//Fetch Tools from server
const fetchToolsStart = (state, action) => {
  return { ...state, error: null, loading: true };
};

const fetchToolsFail = (state, action) => {
  return {
    ...state,
    error: action.error,
    loading: false,
  };
};

const fetchToolsSuccess = (state, action) => {
  return {
    ...state,
    allTools: [...action.tools].map((tool) => {
      // const newTool = cloneDeep(tool);
      // newTool.section = newTool.section.map((newSection) => {
      //   newSection.content = json2html(newSection.content);
      //   return newSection;
      // });
      return cloneDeep(tool);
    }),
    numTools: action.count,
    error: null,
    loading: false,
  };
};

//Fetch tool from IDB
const fetchSavedToolsStart = (state, action) => {
  return {
    ...state,
    fetchSavedIDBToolError: null,
    fetchSavedIDBToolLoading: true,
  };
};

const fetchSavedToolsSuccess = (state, action) => {
  return {
    ...state,
    noSavedTools: action.payload,
    fetchSavedIDBToolError: null,
    fetchSavedIDBToolLoading: false,
    savedIDBTools: [...action.tools],
  };
};

const fetchSavedToolsFail = (state, action) => {
  return {
    ...state,
    fetchSavedIDBToolError: action.error,
    fetchSavedIDBToolLoading: false,
  };
};

//save tool to IDB
const saveToolStart = (state, action) => {
  return { ...state, loading: true, error: null };
};

const saveToolSuccess = (state, action) => {
  return {
    ...state,
    ...clearToolData(),
    ...(action.saveInState && {
      currentTool: {
        toolID: action.payload._id //fetch from server
          ? action.payload._id
          : action.payload.toolID //fetch from IDB can also be shortID
          ? action.payload.toolID
          : "", //not expected to get here
        name: action.payload.name,
        authors: [...action.payload.authors],
        reference: action.payload.reference,
        published: action.payload.publishedDate,
        toolClass: [...action.payload.toolClass],
        toolUsage: [...action.payload.toolUsage],
        section: [...action.payload.section],
        createdAt: action.payload.createdAt,
        collaborators: [...action.payload.collaborators],
      },
    }),
    savedIDBTools: cloneDeep(state.savedIDBTools)
      .filter((tool) => tool.toolID !== action.payload.toolID) //remove old tool with stale data
      .concat(cloneDeep(action.payload)), //to concat new saved tool
    loading: false,
    error: null,
    savedIDBToolsLoading: false,
    savedIDBToolsError: null,
    noSavedTools: false,
  };
};

const saveToolFail = (state, action) => {
  return {
    ...state,
    loading: false,
    error: null,
  };
};

//delete tool from IDB/Server
const deleteToolStart = (state, action) => {
  return { ...state, deleteToolError: null, deleteToolLoading: true };
};

const deleteToolSuccess = (state, action) => {
  return {
    ...state,
    ...clearToolData(),
    deleteToolLoading: false,
    deleteToolError: null,
    deleteToolMessage: action.payload.message,
    noSavedTools: false,

    // savedIDBTools:
    //   action.payload.destination === "local"
    //     ? state.savedIDBTools.filter(
    //         (tool) => tool.toolID !== action.payload.id
    //       )
    //     : [...state.savedIDBTools],

    savedIDBTools: state.savedIDBTools.filter(
      (tool) => tool.toolID !== action.payload.id
    ),

    allTools:
      action.payload.destination === "network"
        ? state.allTools.filter((tool) => tool._id !== action.payload.id)
        : cloneDeep(state.allTools),
    numTools:
      action.payload.destination === "network"
        ? state.numTools - 1
        : state.numTools,
  };
};

const deleteToolFail = (state, action) => {
  return {
    ...state,
    deleteToolError: action.error,
    deleteToolLoading: false,
  };
};

//Update section contents and titles
const updateSections = (state, action) => {
  return {
    ...state,
    currentTool: {
      ...state.currentTool,
      section: [...action.sections],
    },
  };
};

//Fetch tool from server/IDB
const fetchToolByIDStart = (state, action) => {
  return { ...state, error: null, loading: true };
};

const fetchToolByIDFail = (state, action) => {
  return {
    ...state,
    error: action.error,
    loading: false,
  };
};

const fetchToolByIDSuccess = (state, action) => {
  return {
    ...state,
    error: null,
    loading: false,
    currentTool: {
      ...state.currentTool,
      toolID: action.payload._id //fetch from server
        ? action.payload._id
        : action.payload.toolID //fetch from IDB
        ? action.payload.toolID
        : "", //not expected to get here
      name: action.payload.name,
      authors: [
        ...action.payload.authors.map((author) =>
          author.name ? author.name : author
        ),
      ],
      reference: action.payload.reference,
      published: action.payload.publishedDate || action.payload.published,
      toolClass: [...action.payload.toolClass],
      toolUsage: [...action.payload.toolUsage],
      section: [...action.payload.section].map((sect) => {
        const newSection = cloneDeep(sect);
        let newContent;
        try {
          newContent = json2html(JSON.parse(newSection.content));
        } catch (e) {
          newContent = newSection.content;
        }
        newSection.content = newContent;
        return newSection;
      }),
      collaborators: [...action.payload.collaborators],
      createdAt: action.payload.createdAt,
    },
  };
};

//Create & Update tool in server
const postToolStart = (state, action) => {
  return { ...state, error: null, loading: true };
};

const postToolSuccess = (state, action) => {
  return {
    ...state,
    loading: false,
    error: null,
    fetchSavedIDBToolLoading: false,
    fetchSavedIDBToolError: null,
    noSavedTools: false,
    successToolMessage: action.payload.message,
    allTools: [
      ...state.allTools.filter((tool) => tool._id !== action.payload.oldID),
      { ...action.payload.data },
    ],

    savedIDBTools: state.savedIDBTools.filter(
      (tool) => tool.toolID !== action.payload.oldID
    ),
  };
};

const postToolFail = (state, action) => {
  return {
    ...state,
    loading: false,
    error: action.error,
  };
};

const clearToolData = (state, action) => {
  return {
    ...state,
    successToolMessage: null,
    loading: false,
    error: null,
    fetchSavedIDBToolLoading: false,
    fetchSavedIDBToolError: null,
    noSavedTools: false,
    deleteToolLoading: false,
    deleteToolError: null,
    deleteToolMessage: null,
    currentTool: {
      toolID: "",
      name: "",
      authors: [],
      published: new Date(),
      reference: "",
      toolClass: [],
      toolUsage: [],
      section: [],
      createdAt: null,
    },
  };
};

//reducer
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_TOOLS_START:
      return fetchToolsStart(state, action);
    case actionTypes.FETCH_TOOLS_SUCCESS:
      return fetchToolsSuccess(state, action);
    case actionTypes.FETCH_TOOLS_FAIL:
      return fetchToolsFail(state, action);
    case actionTypes.FETCH_SAVED_TOOLS_START:
      return fetchSavedToolsStart(state, action);
    case actionTypes.FETCH_SAVED_TOOLS_FAIL:
      return fetchSavedToolsFail(state, action);
    case actionTypes.FETCH_SAVED_TOOLS_SUCCESS:
      return fetchSavedToolsSuccess(state, action);
    case actionTypes.SAVE_TOOL_START:
      return saveToolStart(state, action);
    case actionTypes.SAVE_TOOL_SUCCESS:
      return saveToolSuccess(state, action);
    case actionTypes.SAVE_TOOL_FAIL:
      return saveToolFail(state, action);
    case actionTypes.POST_TOOL_START:
      return postToolStart(state, action);
    case actionTypes.POST_TOOL_SUCCESS:
      return postToolSuccess(state, action);
    case actionTypes.POST_TOOL_FAIL:
      return postToolFail(state, action);
    case actionTypes.CLEAR_TOOL_DATA:
      return clearToolData(state, action);
    case actionTypes.DELETE_TOOL_START:
      return deleteToolStart(state, action);
    case actionTypes.DELETE_TOOL_SUCCESS:
      return deleteToolSuccess(state, action);
    case actionTypes.DELETE_TOOL_FAIL:
      return deleteToolFail(state, action);
    case actionTypes.UPDATE_TOOL_SECTIONS:
      return updateSections(state, action);
    case actionTypes.FETCH_TOOL_BY_ID_START:
      return fetchToolByIDStart(state, action);
    case actionTypes.FETCH_TOOL_BY_ID_SUCCESS:
      return fetchToolByIDSuccess(state, action);
    case actionTypes.FETCH_TOOL_BY_ID_FAIL:
      return fetchToolByIDFail(state, action);
    default:
      return state;
  }
};

//SELECTORS
export const selectPublishedTools = (tools, id) => {
  if (!tools || tools.length === 0) return null;

  const filteredTools = tools.filter((tool) => {
    if (tool.publisherId) {
      return tool.publisherId._id === id;
    } else {
      return false;
    }
  });
  return selectToolsData(filteredTools);
};

export const selectToolsData = (tools, mini) => {
  const tempTools = [...tools];
  if (!tools || tools.length === 0) return null;
  return tempTools.map((tool, i) => {
    const tempTool = {};
    tempTool.toolName = tool.name;
    tempTool.createdAt = new Date(tool.createdAt).toUTCString();
    tempTool.publisher = tool.publisherId
      ? tool.publisherId.name
      : "No Publisher";

    if (!mini) {
      let updated = "No update yet";
      if (tool.updatedAt) updated = new Date(tool.updatedAt).toUTCString();
      tempTool.updatedAt = updated;

      tempTool.collaborators =
        tool.collaborators && tool.collaborators.length > 0
          ? tool.collaborators.map((collab) => collab.name).join(", ")
          : "None";
    }

    Object.defineProperty(tempTool, "index", {
      enumerable: false,
      configurable: false,
      writable: false,
      value: i,
    });

    Object.defineProperty(tempTool, "id", {
      enumerable: false,
      configurable: false,
      writable: false,
      value: tool._id,
    });

    return tempTool;
  });
};

export const selectSavedToolsData = (tools) => {
  const tempTools = [...tools];
  if (!tools || tools.length === 0) return [];
  return tempTools.map((tool, i) => {
    const tempTool = {};
    tempTool.toolName = tool.name;
    tempTool.createdAt = new Date(tool.createdAt).toUTCString();
    tempTool.updatedAt = "Not yet posted";
    tempTool.publisher = "You";
    tempTool.collaborators =
      tool.collaborators && tool.collaborators.length > 0
        ? tool.collaborators.map((collab) => collab.name).join(", ")
        : "None";

    Object.defineProperty(tempTool, "index", {
      enumerable: false,
      configurable: false,
      writable: false,
      value: i,
    });

    Object.defineProperty(tempTool, "id", {
      enumerable: false,
      configurable: false,
      writable: false,
      value: tool.toolID,
    });

    return tempTool;
  });
};

export const selectOtherToolsData = (tools) => {
  const tempTools = [...tools];
  if (!tools || tools.length === 0) return null;
  return tempTools.map((tool) => {
    const tempTool = {};
    tempTool.authors = tool.authors
      .map((author) => (author.name ? author.name : author))
      .join(", ");
    tempTool.section = tool.section.length;
    const date = tool.publishedDate ? tool.publishedDate : tool.published;
    tempTool.publishedDate = new Date(date).toDateString().substr(4);
    tempTool.toolClass = tool.toolClass.join(", ");
    tempTool.toolUsage = tool.toolUsage.join(", ");
    tempTool.reference = tool.reference;
    return tempTool;
  });
};

export const selectSectionsContent = (sections) => {
  return sections.map((section) =>
    section.content ? section.content : section
  );
};

export const selectSectionsTitles = (tools) => {
  return tools.section.map((section) =>
    section.title ? section.title : section
  );
};

export default reducer;
