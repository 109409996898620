//Authentication
export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_INITIATE_LOGOUT = "AUTH_INITIATE_LOGOUT";
export const AUTH_CHECK_TIMEOUT = "AUTH_CHECK_TIMEOUT";
export const AUTH_USER = "AUTH_USER";
export const AUTH_CHECK_STATE = "AUTH_CHECK_STATE";
export const SET_AUTH_REDIRECT_PATH = "SET_AUTH_REDIRECT_PATH";
export const AUTH_CLEAR_ERROR = "AUTH_CLEAR_ERROR";

//Registration
export const REGISTRATION_START = "REGISTRATION_START";
export const REGISTRATION_SUCCESS = "REGISTRATION_SUCCESS";
export const REGISTRATION_FAIL = "REGISTRATION_FAIL";
export const REGISTER_USER = "REGISTER_USER";

//DBS
export const LOAD_DBS_START = "LOAD_DBS_START";
export const LOAD_DBS_SUCCESS = "LOAD_DBS_SUCCESS";
export const LOAD_DBS_FAIL = "LOAD_DBS_FAIL";
export const LOAD_DBS = "LOAD_DBS";

//Tools
export const FETCH_TOOLS_START = "FETCH_TOOLS_START";
export const FETCH_TOOLS_SUCCESS = "FETCH_TOOLS_SUCCESS";
export const FETCH_TOOLS_FAIL = "FETCH_TOOLS_FAIL";
export const FETCH_TOOLS = "FETCH_TOOLS";

export const SAVE_TOOL = "SAVE_TOOL";
export const SAVE_TOOL_START = "SAVE_TOOL_START";
export const SAVE_TOOL_SUCCESS = "SAVE_TOOL_SUCCESS";
export const SAVE_TOOL_FAIL = "SAVE_TOOL_FAIL";

export const POST_TOOL = "POST_TOOL";
export const POST_TOOL_START = "POST_TOOL_START";
export const POST_TOOL_SUCCESS = "POST_TOOL_SUCCESS";
export const POST_TOOL_FAIL = "POST_TOOL_FAIL";

export const DELETE_TOOL = "DELETE_TOOL";
export const DELETE_TOOL_START = "DELETE_TOOL_START";
export const DELETE_TOOL_SUCCESS = "DELETE_TOOL_SUCCESS";
export const DELETE_TOOL_FAIL = "DELETE_TOOL_FAIL";

export const FETCH_SAVED_TOOLS = "FETCH_SAVED_TOOLS";
export const FETCH_SAVED_TOOLS_SUCCESS = "FETCH_SAVED_TOOLS_SUCCESS";
export const FETCH_SAVED_TOOLS_START = "FETCH_SAVED_TOOLS_START";
export const FETCH_SAVED_TOOLS_FAIL = "FETCH_SAVED_TOOLS_FAIL";

export const FETCH_TOOL_BY_ID = "FETCH_TOOL_BY_ID";
export const FETCH_TOOL_BY_ID_SUCCESS = "FETCH_TOOL_BY_ID_SUCCESS";
export const FETCH_TOOL_BY_ID_START = "FETCH_TOOL_BY_ID_START";
export const FETCH_TOOL_BY_ID_FAIL = "FETCH_TOOL_BY_ID_FAIL";

export const UPDATE_TOOL_SECTIONS = "UPDATE_TOOL_SECTIONS";
export const CLEAR_TOOL_DATA = "CLEAR_TOOL_DATA";

export const MINIFIED_LAYOUT = "MINIFIED_LAYOUT";
export const ACTIVATE_NEW_TOOL_FORM = "ACTIVATE_NEW_TOOL_FORM";
export const EDIT_MODE_ON = "EDIT_MODE_ON";

export const TOOLTIP_ON = "TOOLTIP_ON";
export const TOOLTIP_OFF = "TOOLTIP_OFF";

export const TOAST_ON = "TOAST_ON";
export const TOAST_OFF = "TOAST_OFF";

export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
