import React from "react";
import Hero from "../Hero/Hero";
import classes from "./header.module.scss";

const Header = () => {
  return (
    <div className={classes.Header}>
      <div className={classes.Container}>
        <Hero />
      </div>
    </div>
  );
};

export default Header;
