import React from "react";
import classes from "./mainarea.module.scss";
import { Link } from "react-router-dom";
import { ReactComponent as Usage } from "../../imgs/usage.svg";
import { ReactComponent as TClass } from "../../imgs/class.svg";
import { ReactComponent as Combined } from "../../imgs/combine.svg";
import { ReactComponent as Pipeline } from "../../imgs/workflow.svg";

const MainArea = () => {
  return (
    <div className={classes.MainArea}>
      <h3>The following types of analysis are provided</h3>
      <span>&nbsp;</span>
      <div className={classes.AnalysisGroup}>
        <div className={classes.AnalysisBox}>
          <div className={classes.AnalysisTop}>
            <Usage className={classes.SVGIcon} />
            <p>
              <Link to={"/manual/usage"}>Execute Analysis by Tool Usage</Link>
            </p>
          </div>
          <div className={classes.AnalysisBottom}>
            In this analysis section, you will be able to execute individual
            pGWAS analysis based on their usages, and a few categories includes:
            Meta-analysis, Conditional association and imputation using summary
            statistics, Polygenic predictions etc.
          </div>
        </div>
        <div className={classes.AnalysisBox}>
          <div className={classes.AnalysisTop}>
            <TClass className={classes.SVGIcon} />
            <p>
              <Link to={"/manual/class"}>Execute Analysis by Tool Class</Link>
            </p>
          </div>
          <div className={classes.AnalysisBottom}>
            In this analysis section, you will be able to perform an individual
            pGWAS analysis based on the following categories: Single-variant
            approach, Gene-scoring/based approach, Pathway-sub-network-based
            approaches.
          </div>
        </div>
        <div className={classes.AnalysisBox}>
          <div className={classes.AnalysisTop}>
            <Combined className={classes.SVGIcon} />
            <p>Execute A Combined Analysis</p>
          </div>
          <div className={classes.AnalysisBottom}>
            In this analysis, you can utilize our synergized workflow that
            targets mainstream pGWAS challenges. You will be able to execute
            pGWAS analysis with combination of various pGWAS analysis in a
            single run.
          </div>
        </div>
        <div className={classes.AnalysisBox}>
          <div className={classes.AnalysisTop}>
            <Pipeline className={classes.SVGIcon} />
            <p>Build custom pipeline</p>
          </div>
          <div className={classes.AnalysisBottom}>
            In this analysis step, you will be able to build your own custom
            workflow using the different combination of tools as different steps
            in the previous analysis into a full pipeline.
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainArea;
