import React from "react";
import Spinner from "../UI/Spinner/Spinner";
import classes from "./comingsoon.module.scss";

const ComingSoon = () => {
  // console.log(props);
  return (
    <div className={classes.ComingSoon}>
      <Spinner type="rotate" />
      <p>Coming Soon...</p>
    </div>
  );
};

export default ComingSoon;
