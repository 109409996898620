import React from "react";
import classes from "./hero.module.scss";
import Lines from "../Lines/Lines";
import BoxImage from "../BoxImages/BoxImage";

const Hero = () => {
  return (
    <div className={classes.Hero}>
      <div className={classes.Col1}>
        <Lines />
        <BoxImage />
      </div>

      <div className={classes.Col2}>
        <p>SysBiol WG PostGWAS analysis tool</p>
      </div>
    </div>
  );
};

export default Hero;
