import * as actionTypes from "./actionTypes";

//ACTIONS MOSTLY CALLED BY APPLICATION OR SAGAS

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authSuccess = (idToken, userId, verified, name, role, photo) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    idToken,
    userId,
    verified,
    name,
    role,
    photo,
  };
};

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error,
  };
};

export const logout = () => {
  //Action dispatched to the saga
  return {
    type: actionTypes.AUTH_INITIATE_LOGOUT,
  };
};

export const logoutSucceed = () => {
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const checkAuthTimeout = (expirationTime) => {
  return {
    type: actionTypes.AUTH_CHECK_TIMEOUT,
    expirationTime,
  };
};

export const auth = (payload) => {
  return {
    type: actionTypes.AUTH_USER,
    email: payload.email,
    password: payload.password,
    keepLoggedIn: payload.keepLoggedIn,
  };
};

export const setAuthRedirectPath = (path) => {
  return {
    type: actionTypes.SET_AUTH_REDIRECT_PATH,
    path,
  };
};

export const authCheckState = () => {
  return {
    type: actionTypes.AUTH_CHECK_STATE,
  };
};

//Registration
export const registrationStart = () => {
  return {
    type: actionTypes.REGISTRATION_START,
  };
};

export const registrationSuccess = (message) => {
  return {
    type: actionTypes.REGISTRATION_SUCCESS,
    message,
  };
};

export const registrationFail = (error) => {
  return {
    type: actionTypes.REGISTRATION_FAIL,
    error,
  };
};

export const register = (payload) => {
  return {
    type: actionTypes.REGISTER_USER,
    ...payload,
  };
};

export const authClearError = () => {
  return {
    type: actionTypes.AUTH_CLEAR_ERROR,
  };
};
