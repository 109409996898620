/* eslint-disable no-fallthrough */
import { openDB } from "idb/with-async-ittr";

export default class dataIDB {
  constructor(name, version, store, keypath) {
    if (!("indexedDB" in window)) {
      throw new Error("No IndexDB support!");
    }
    this.name = name;
    this.version = version;
    this.store = store;
    this.keypath = keypath;
    // this.createDB();
  }

  // CREATE DB
  async createDB() {
    // console.log(`Creating ${this.name}, version ${this.version}...`);

    const storename = this.store;
    const keypath = this.keypath;

    this.dbPromise = await openDB(this.name, this.version, {
      upgrade(db) {
        // Create a store of objects
        // console.log(`Creating the ${storename} object store`);

        const store = db.createObjectStore(storename, {
          // The 'id' property of the object will be the key.
          keyPath: keypath,
          // If it isn't explicitly set, create a value by auto incrementing.
          // autoIncrement: true,
        });
        // Create an index on the 'name' property of the objects.
        store.createIndex(keypath, keypath, { unique: true });
      },
    });
  }

  //Get all data
  async getAllData(index) {
    if (index === "") return;
    // console.log(`Fetching ${index} from db...`);
    return await this.dbPromise.getAllFromIndex(this.store, index);
  }

  // READ
  getData(query) {
    // use the get method to get an object by name
    if (query.trim() === "") return;

    // console.log(`Fetching ${query} from db...`);
    const tx = this.dbPromise.transaction(this.store, "readonly");
    const store = tx.objectStore(this.store);
    const index = store.index(this.keypath);
    return index.get(query).then((data) => data);
  }

  // CREATE
  async addData(key, jsonData) {
    // All database operations must be carried out within a transaction.
    // we first open the transaction on the database object and then open the object store on the transaction.
    // Now when we call store.add on that object store, the operation happens inside the transaction.
    if (!key && !jsonData) return;

    const tx = this.dbPromise.transaction(this.store, "readwrite");
    const store = tx.objectStore(this.store);
    // console.log("Adding key: ", key);

    try {
      await store.add(jsonData);
      // console.log(`${key} added successfully!`);
    } catch (error) {
      tx.abort();
      throw new Error(error.message);
    }
  }

  //UPDATE
  async updateData(key, jsonData) {
    const tx = this.dbPromise.transaction(this.store, "readwrite");
    const store = tx.objectStore(this.store);

    try {
      await store.put(jsonData);
      // console.log(`${key} updated successfully!`);
    } catch (e) {
      tx.abort();
      // console.log(e);
    }
  }

  //Delete
  async deleteData(key) {
    // All database operations must be carried out within a transaction.
    // we first open the transaction on the database object and then open the object store on the transaction.
    // Now when we call store.delete on that object store, the operation happens inside the transaction.
    if (!key) return;

    const tx = this.dbPromise.transaction(this.store, "readwrite");
    const store = tx.objectStore(this.store);
    // console.log("deleting: ", key);

    try {
      await store.delete(key);
      // console.log(`${key} deleted successfully!`);
    } catch (error) {
      tx.abort();
    }
  }
}

//load index DBs
export const savedToolsdb = new dataIDB(
  "savedToolsdb__test",
  1,
  "tools",
  "toolID"
);

// export const savedToolsSectionsDB = new dataIDB(
//   "savedToolsSectionsDB__test",
//   1,
//   "sections",
//   "name"
// );
