import { put } from "redux-saga/effects";
import * as actions from "../actions";
import { savedToolsdb } from "../../indexDB";

export function* loadDBsSaga(action) {
  // console.log("Loading dbs");
  yield put(actions.loadDBsStart());

  try {
    yield savedToolsdb.createDB();

    //successful fetch
    yield put(actions.loadDBsSuccess(true));
    // console.log("Loaded dbs");
  } catch (error) {
    yield put(actions.loadDBsFail(error.message));
  }
}
