import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import classes from "./nav.module.scss";
import { useSelector } from "react-redux";
//activeClassName={classes.active}
const Nav = React.forwardRef((props, ref) => {
  //Handle not logged in redirect
  const isAuthenticated = useSelector((state) => {
    return state.auth.token !== null && state.auth.token !== undefined;
  });

  useEffect(() => {
    return () => {
      window.document.body.style.overflow = "visible";
    };
  }, []);

  const currentPage = useSelector((state) => state.ui.currentPage);

  return (
    <div ref={ref} className={classes.NavBG}>
      <div className={classes.Container}>
        <div className={classes.NavSection}>
          <div className={classes.Logo}>
            Sysbiol<span>PGWAS</span>
          </div>

          <div
            onClick={(e) => {
              const element = e.target.closest(
                `div.${classes.navigation__button}`
              );

              element.classList.toggle(classes.checked);
              window.document.body.style.overflow = element.classList.contains(
                classes.checked
              )
                ? "hidden"
                : "visible";
            }}
            className={classes.navigation__button}
          >
            <div className={classes.navigation__icon}>&nbsp;</div>
          </div>

          <nav className={classes.Nav}>
            <div
              onClick={(e) => {
                const element = e.target.parentElement.previousSibling;

                element.classList.toggle(classes.checked);

                window.document.body.style.overflow = element.classList.contains(
                  classes.checked
                )
                  ? "hidden"
                  : "visible";
                // console.dir(e.target);
              }}
              className={classes.Backdrop}
            >
              &nbsp;
            </div>
            <ul>
              <li>
                <NavLink exact activeClassName={classes.active} to={"/"}>
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink exact activeClassName={classes.active} to={"/manual"}>
                  Manual
                </NavLink>
              </li>
              <li>
                <NavLink exact to={"/tutorials"}>
                  Tutorials
                </NavLink>
              </li>
              <li>
                <NavLink exact to={"/toolsbyusage"}>
                  Tools by Usage
                </NavLink>
              </li>
              <li>
                <NavLink exact to={"/toolsbyclass"}>
                  Tools by Classes
                </NavLink>
              </li>
              {!isAuthenticated && (
                <li>
                  <NavLink
                    exact
                    to={
                      currentPage && currentPage === "manual"
                        ? "/publishers/login"
                        : "/users/login"
                    }
                  >
                    Login
                  </NavLink>
                </li>
              )}
              {!isAuthenticated && (
                <li>
                  <NavLink
                    exact
                    to={
                      currentPage && currentPage === "manual"
                        ? "/publishers/register"
                        : "/users/register"
                    }
                  >
                    Register
                  </NavLink>
                </li>
              )}
              {isAuthenticated && (
                <li>
                  <NavLink
                    exact
                    to={
                      currentPage && currentPage === "manual"
                        ? "/publishers"
                        : "/users"
                    }
                  >
                    Dashboard
                  </NavLink>
                </li>
              )}
              <li>
                <NavLink exact to={"/about"}>
                  About
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
});

export default Nav;
