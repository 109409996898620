import React from "react";
import classes from "./Spinner.module.scss";

const spinner = (props) => {
  const { type } = props;

  switch (type) {
    case "bars":
      return (
        <div className={classes.Bars}>
          <div className={classes.rect1}>&nbsp;</div>
          <div className={classes.rect2}>&nbsp;</div>
          <div className={classes.rect3}>&nbsp;</div>
          <div className={classes.rect4}>&nbsp;</div>
          <div className={classes.rect5}>&nbsp;</div>
        </div>
      );
    case "chase":
      return (
        <div className={classes["sk-chase"]}>
          <div className={classes["sk-chase-dot"]}>&nbsp;</div>
          <div className={classes["sk-chase-dot"]}>&nbsp;</div>
          <div className={classes["sk-chase-dot"]}>&nbsp;</div>
          <div className={classes["sk-chase-dot"]}>&nbsp;</div>
          <div className={classes["sk-chase-dot"]}>&nbsp;</div>
          <div className={classes["sk-chase-dot"]}>&nbsp;</div>
        </div>
      );
    case "card-flip":
      return <div className={classes.CardFlip}>&nbsp;</div>;
    case "box-break":
      return (
        <div className={classes["sk-cube-grid"]}>
          <div className={[classes["sk-cube"], classes["sk-cube1"]].join(" ")}>
            &nbsp;
          </div>
          <div className={[classes["sk-cube"], classes["sk-cube2"]].join(" ")}>
            &nbsp;
          </div>
          <div className={[classes["sk-cube"], classes["sk-cube3"]].join(" ")}>
            &nbsp;
          </div>
          <div className={[classes["sk-cube"], classes["sk-cube4"]].join(" ")}>
            &nbsp;
          </div>
          <div className={[classes["sk-cube"], classes["sk-cube5"]].join(" ")}>
            &nbsp;
          </div>
          <div className={[classes["sk-cube"], classes["sk-cube6"]].join(" ")}>
            &nbsp;
          </div>
          <div className={[classes["sk-cube"], classes["sk-cube7"]].join(" ")}>
            &nbsp;
          </div>
          <div className={[classes["sk-cube"], classes["sk-cube8"]].join(" ")}>
            &nbsp;
          </div>
          <div className={[classes["sk-cube"], classes["sk-cube9"]].join(" ")}>
            &nbsp;
          </div>
        </div>
      );
    case "box-fold":
      return (
        <div className={classes["sk-folding-cube"]}>
          <div className={[classes["sk-cube1"], classes["sk-cube"]].join(" ")}>
            &nbsp;
          </div>
          <div className={[classes["sk-cube2"], classes["sk-cube"]].join(" ")}>
            &nbsp;
          </div>
          <div className={[classes["sk-cube4"], classes["sk-cube"]].join(" ")}>
            &nbsp;
          </div>
          <div className={[classes["sk-cube3"], classes["sk-cube"]].join(" ")}>
            &nbsp;
          </div>
        </div>
      );
    case "bounce":
      return (
        <div className={classes.progressbar}>
          <div className={classes.inner}>&nbsp;</div>
        </div>
      );
    case "rotate":
      return (
        <div className={classes.scene}>
          <div className={classes.cube}>
            <div
              className={[
                classes.cube__face,
                classes["cube__face--front"],
              ].join(" ")}
            >
              Work in progress
            </div>
            <div
              className={[classes.cube__face, classes["cube__face--back"]].join(
                " "
              )}
            >
              Coming Soon...
            </div>
            <div
              className={[
                classes.cube__face,
                classes["cube__face--right"],
              ].join(" ")}
            >
              <div className={classes.Logo}>
                Sysbiol<span>PGWAS</span>
              </div>
            </div>
            <div
              className={[classes.cube__face, classes["cube__face--left"]].join(
                " "
              )}
            >
              <div className={classes.Logo}>
                Sysbiol<span>PGWAS</span>
              </div>
            </div>
            <div
              className={[classes.cube__face, classes["cube__face--top"]].join(
                " "
              )}
            >
              &nbsp;
            </div>
            <div
              className={[
                classes.cube__face,
                classes["cube__face--bottom"],
              ].join(" ")}
            >
              &nbsp;
            </div>
          </div>
        </div>
      );
    default:
      return <div className={classes.Loader}>Loading...</div>;
  }
};

export default spinner;
