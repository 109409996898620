import { put } from "redux-saga/effects";
import * as actions from "../actions";
import axios, { setHeaders } from "../../axios-fetches";
import { savedToolsdb } from "../../indexDB";

//where all tools side effects are handled

export function* fetchToolsSaga(action) {
  yield put(actions.fetchToolsStart());

  //Handle query string
  const queryString =
    "?select=name,toolClass,toolUsage,collaborators,section.title,authors,reference,createdAt,updatedAt,publishedDate";

  try {
    setHeaders();
    const response = yield axios.get("/tools" + queryString);
    if (!response.data.success) throw new Error(response.data.error);

    //successful fetch
    yield put(
      actions.fetchToolsSuccess(response.data.data, response.data.count)
    );
  } catch (error) {
    yield put(actions.fetchToolsFail(error.message));
  }
}

export function* saveToolSaga(action) {
  yield put(actions.saveToolStart());

  //Save to indexedDB
  try {
    yield savedToolsdb.updateData("toolID", {
      ...action.payload,
    });

    yield put(actions.saveToolSuccess(action.payload, action.saveInState));
  } catch (error) {
    yield put(actions.saveToolFail(error.message));
  }
}

export function* deleteToolSaga(action) {
  yield put(actions.deleteToolStart());

  try {
    //delete from indexedDB
    if (action.destination === "local") {
      yield savedToolsdb.deleteData(action.id);
      yield put(
        actions.deleteToolSuccess({
          message: `${action.id} deleted!`,
          id: action.id,
          destination: action.destination,
        })
      );
    } else {
      //delete from server
      setHeaders();
      const response = yield axios.delete("/tools/" + action.id);
      if (!response.data.success) throw new Error(response.data.error);
      yield put(
        actions.deleteToolSuccess({
          message: response.data.success
            ? `${action.id} deleted!`
            : "Error! delete failed!!",
          id: action.id,
          destination: action.destination,
        })
      );
    }
  } catch (error) {
    yield put(actions.deleteToolFail(error.message));
  }
}

export function* fetchSavedToolsSaga(action) {
  yield put(actions.fetchSavedToolsStart());

  try {
    let data = yield savedToolsdb.getAllData(action.index);

    if (!data || data.length === 0) {
      yield put(actions.fetchSavedToolsSuccess(data, true));
    } else {
      //successful fetch
      yield put(actions.fetchSavedToolsSuccess(data, false));
    }
  } catch (error) {
    yield put(actions.fetchSavedToolsFail(error.message));
  }
}

export function* fetchToolByID(action) {
  yield put(actions.fetchToolByIDStart());

  try {
    if (action.destination === "local") {
      const tool = yield savedToolsdb.getData(action.id);
      yield put(actions.fetchToolByIDSuccess(tool));
    } else if (action.destination === "network") {
      setHeaders();
      const response = yield axios.get("/tools/" + action.id);

      if (!response.data.success) throw new Error(response.data.error);

      //successful fetch
      yield put(actions.fetchToolByIDSuccess(response.data.data));
    }
  } catch (e) {
    yield put(actions.fetchToolsFail(e.message));
  }
}

export function* postToolSaga(action) {
  yield put(actions.postToolStart());

  //send to server
  try {
    let response;
    const toolID = action.payload.toolID;
    if (toolID && toolID.length >= 15) {
      Reflect.deleteProperty(action.payload, "toolID");
      Reflect.deleteProperty(action.payload, "collaborators");
      // delete action.payload.toolID;
      // delete action.payload.collaborators;
      setHeaders();
      response = yield axios.put(`/tools/${toolID}`, action.payload);
    } else if (toolID && toolID.length > 0 && toolID.length < 15) {
      delete action.payload.toolID;
      setHeaders();
      response = yield axios.post("/tools", action.payload);
    } else {
      throw new Error("Wrong Tool Data");
    }

    if (!response.data.success) throw new Error(response.data.error);

    yield put(
      actions.postToolSuccess({
        data: { ...response.data.data },
        message: `${action.payload.name} successfully added!`,
        oldID: toolID,
      })
    );

    //delete saved instances from indexedDB
    yield savedToolsdb.deleteData(toolID);
  } catch (error) {
    yield put(
      actions.postToolFail(
        `There was an error! Save incomplete! Try again later... Find Tool in "Saved tools" tab. Error from message server: ${error.message}`
      )
    );
  }
}
