// import * as actionTypes from "../actions/actionTypes";

import * as actionTypes from "../actions/actionTypes";

const initialState = {
  activateForm: false,
  miniSidebar: false,
  editMode: false,
  tooltip: {
    message: "",
    show: false,
    position: {},
    type: "",
  },
  toast: {
    type: "",
    message: "",
    display: false,
  },
  currentPage: "home",
};

const setMinified = (state, action) => {
  return {
    ...state,
    miniSidebar: action.value,
  };
};

const activateNewForm = (state, action) => {
  return {
    ...state,
    activateForm: action.value,
  };
};

const setEditMode = (state, action) => {
  return {
    ...state,
    editMode: action.value,
  };
};

const setTooltipON = (state, action) => {
  // console.log(action);
  return {
    ...state,
    tooltip: {
      message: action.payload.message,
      show: action.payload.show,
      position: {
        ...state.position,
        ...action.payload.position,
      },
      type: action.payload.type,
    },
  };
};

const setTooltipOFF = (state, action) => {
  return {
    ...state,
    tooltip: {
      message: "",
      show: false,
      position: {},
      type: "",
    },
  };
};

const setToastON = (state, action) => {
  // console.log(action);
  return {
    ...state,
    toast: {
      message: action.payload.message,
      display: action.payload.display,
      type: action.payload.type,
    },
  };
};

const setToastOFF = (state, action) => {
  return {
    ...state,
    toast: {
      type: "",
      message: "",
      display: false,
    },
  };
};

const setPage = (state, action) => {
  return {
    ...state,
    currentPage: action.payload,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.MINIFIED_LAYOUT:
      return setMinified(state, action);
    case actionTypes.ACTIVATE_NEW_TOOL_FORM:
      return activateNewForm(state, action);
    case actionTypes.EDIT_MODE_ON:
      return setEditMode(state, action);
    case actionTypes.TOOLTIP_ON:
      return setTooltipON(state, action);
    case actionTypes.TOOLTIP_OFF:
      return setTooltipOFF(state, action);
    case actionTypes.TOAST_ON:
      return setToastON(state, action);
    case actionTypes.TOAST_OFF:
      return setToastOFF(state, action);
    case actionTypes.SET_CURRENT_PAGE:
      return setPage(state, action);
    default:
      return state;
  }
};

export default reducer;
