import React, { Suspense, useEffect, useRef } from "react";
import Nav from "../components/Nav/Nav";
import { Link, Route, Switch } from "react-router-dom";
// import Manual from "./Manual/Manual";
import Home from "./Home/Home";
import Footer from "../components/Footer/Footer";
import ComingSoon from "../components/ComingSoon/ComingSoon";
import Spinner from "../components/UI/Spinner/Spinner";
const Manual = React.lazy(() => import("./Manual/Manual"));
const IndexPage = () => {
  const navEl = useRef(null);
  let timer = useRef(null);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = (event) => {
    if (timer.current) {
      clearTimeout(timer.current);
    }

    timer.current = setTimeout(() => {
      // let scrollTop = event.target.body.scrollTop;
      let scrollTop = document.documentElement.scrollTop;
      if (scrollTop >= 10) {
        navEl.current.style.backgroundColor = "black";
        navEl.current.style.color = "white !important";
        navEl.current.querySelector("nav").style.backgroundColor = "black";
        navEl.current.querySelector("nav").style.color = "white !important";
      } else {
        navEl.current.style.backgroundColor = "";
        navEl.current.style.background =
          "rgba(#72549a, 0.9) url('../imgs/index.png') no-repeat 49px 0/cover";

        navEl.current.querySelector("nav").style.backgroundColor = "";
        navEl.current.querySelector("nav").background =
          "rgba(#72549a, 0.9) url('../imgs/index.png') no-repeat 49px 0/cover";
      }
    }, 100);
  };

  return (
    <React.Fragment>
      <Nav ref={navEl} />
      <Switch>
        <Route path="/tutorials" component={ComingSoon} />
        <Route path="/toolsbyusage" exact component={ComingSoon} />
        <Route path="/toolsbyclass" exact component={ComingSoon} />
        <Route path={"/about"} component={ComingSoon} />
        <Route
          path="/manual"
          // component={Manual}
          render={(props) => (
            <Suspense
              fallback={
                <div>
                  <Spinner type="bounce" />
                </div>
              }
            >
              <Manual {...props} />
            </Suspense>
          )}
        />
        <Route path="/" exact component={Home} />
        {/*<Redirect from="/" to="/404" />*/}
        <Route
          render={(props) => (
            <h1 style={{ padding: "10rem" }}>
              404 Not Found! <Link to={props.match.url}>Go Home </Link>
            </h1>
          )}
        />
      </Switch>
      <Footer />
    </React.Fragment>
  );
};

export default IndexPage;
