import React from "react";
import boxImage1 from "../../imgs/banner_img1.webp";
import boxImage2 from "../../imgs/metabolism.webp";
import boxImage3 from "../../imgs/banner_img3.webp";
import classes from "./boxImage.module.scss";

const BoxImage = () => {
  return (
    <div className={classes.BoxImages}>
      <div className={[classes.Image, classes.ImageOne].join(" ")}>
        <img src={boxImage1} alt="" />
      </div>
      <div className={[classes.Image, classes.ImageTwo].join(" ")}>
        <img src={boxImage2} alt="" />
      </div>
      <div className={[classes.Image, classes.ImageThree].join(" ")}>
        <img src={boxImage3} alt="" />
      </div>
    </div>
  );
};

export default BoxImage;
