import React from "react";
import LinesOne from "../../imgs/line_01_part1.webp";
import LinesFour from "../../imgs/line_01_part2.webp";
import LinesTwo from "../../imgs/lines2.webp";
import LinesThree from "../../imgs/lines3.webp";
import classes from "./lines.module.scss";

const Lines = () => {
  return (
    <div className={classes.Lines}>
      <div className={classes.LineOne}>
        <img src={LinesOne} alt="lines" />
      </div>
      <div className={classes.LineFour}>
        <img src={LinesFour} alt="lines" />
      </div>
      <div className={classes.LineTwo}>
        <img src={LinesTwo} alt="lines" />
      </div>
      <div className={classes.LineThree}>
        <img src={LinesThree} alt="lines" />
      </div>
    </div>
  );
};

export default Lines;
