import { put } from "redux-saga/effects";
import { delay } from "redux-saga/effects";
import axios, { setHeaders } from "../../axios-fetches";

import * as actions from "../actions";

export function* logoutSaga(action) {
  //yield token, expiry date and userid removal from indexed db
  yield localStorage.removeItem("authuser");

  //put dispatches actions to reducer
  yield put(actions.logoutSucceed());
  setHeaders();
  yield axios.get("/auth/logout");
}

//Kick start expiration of token
export function* checkAuthTimeoutSaga(action) {
  yield delay(action.expirationTime);
  yield put(actions.logout());
}

export function* authUserSaga(action) {
  yield put(actions.authStart());
  const authData = {
    email: action.email,
    password: action.password,
    keepLoggedIn: action.keepLoggedIn,
  };

  try {
    const response = yield axios.post("/auth/login", authData);

    if (!response.data.success) throw new Error(response.data.error);

    const expirationDate = yield new Date(Date.now() + response.data.expiresIn);

    // yield adding login token, exp date,user id to indexed db
    yield localStorage.setItem(
      "authuser",
      JSON.stringify({
        userID: response.data.userID,
        token: response.data.token,
        expiresIn: expirationDate,
        verified: response.data.verified,
        username: response.data.username,
        role: response.data.role,
        photo: response.data.photo,
      })
    );

    yield put(
      actions.authSuccess(
        response.data.token,
        response.data.userID,
        response.data.verified,
        response.data.username,
        response.data.role,
        response.data.photo
      )
    ); //successful login
    yield put(actions.checkAuthTimeout(response.data.expiresIn)); //kickstart timer for expiration of token
    // yield put(actions.setAuthRedirectPath());
  } catch (error) {
    yield put(actions.authFail(error.message));
  }
}

//Auto sign in on reload
export function* authCheckStateSaga() {
  // Get token userId, and exp date from indexed db
  let authuser = yield localStorage.getItem("authuser");

  if (!authuser) {
    yield put(actions.logout());
  } else {
    const {
      token,
      userID,
      expiresIn,
      verified,
      username,
      role,
      photo,
    } = JSON.parse(authuser);

    const expirationDate = yield new Date(expiresIn);
    if (expirationDate < new Date()) {
      yield put(actions.logout());
    } else {
      yield put(
        actions.authSuccess(token, userID, verified, username, role, photo)
      );
      yield put(
        actions.checkAuthTimeout(
          expirationDate.getTime() - new Date().getTime()
        )
      );
    }
  }
}

//Register User
export function* registerUserSaga(action) {
  yield put(actions.registrationStart());

  const registerData = {
    ...action,
  };

  try {
    delete registerData.type;
    const response = yield axios.post("/auth/register", registerData);
    if (!response.data.success) throw new Error(response.data.error);

    yield put(actions.registrationSuccess(response.data.data)); //successful registration
  } catch (error) {
    yield put(actions.registrationFail(error.message));
  }
}
