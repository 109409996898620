import { takeEvery } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";
import {
  logoutSaga,
  checkAuthTimeoutSaga,
  authUserSaga,
  authCheckStateSaga,
  registerUserSaga,
} from "./auths";
import { loadDBsSaga } from "./dbs";

import {
  fetchToolsSaga,
  saveToolSaga,
  fetchSavedToolsSaga,
  fetchToolByID,
  deleteToolSaga,
  // saveToolContentSaga,
  postToolSaga,
} from "./tools";

export function* watchAuth() {
  //TakeEvery acts as a listener that executes logoutSaga when initiate logout is dispatched
  yield takeEvery(actionTypes.AUTH_INITIATE_LOGOUT, logoutSaga);
  yield takeEvery(actionTypes.AUTH_CHECK_TIMEOUT, checkAuthTimeoutSaga);
  yield takeEvery(actionTypes.AUTH_USER, authUserSaga);
  yield takeEvery(actionTypes.REGISTER_USER, registerUserSaga);
  yield takeEvery(actionTypes.AUTH_CHECK_STATE, authCheckStateSaga);
}

export function* watchTools() {
  //TakeEvery acts as a listener that executes logoutSaga when initiate logout is dispatched
  yield takeEvery(actionTypes.FETCH_TOOLS, fetchToolsSaga);
  yield takeEvery(actionTypes.POST_TOOL, postToolSaga);
  yield takeEvery(actionTypes.FETCH_SAVED_TOOLS, fetchSavedToolsSaga);
  yield takeEvery(actionTypes.SAVE_TOOL, saveToolSaga);
  yield takeEvery(actionTypes.DELETE_TOOL, deleteToolSaga);
  yield takeEvery(actionTypes.FETCH_TOOL_BY_ID, fetchToolByID);
}

export function* watchDBs() {
  //TakeEvery acts as a listener that executes logoutSaga when initiate logout is dispatched
  yield takeEvery(actionTypes.FETCH_TOOLS, loadDBsSaga);
}
